import { h } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from 'vue-router'
import { getCookie, removeCookie } from 'typescript-cookie'
import { UserService } from '@/services/UserService'
import { SUPPORTED_LANGUAGES } from '@/constants/translation';
import { useTranslation } from '@/composables/useTranslation';

// This will be used for checking the language prefix in the URL
const { routeMiddleware } = useTranslation();

const routes: Array<RouteRecordRaw> = [
	// if there's no lang prefix in the URL then force to use the first supported lang
	// will match everything and put it under `$route.params.pathMatch`
	{
		path: '/:pathMatch(.*)*',
		redirect: {
			path: '/de/dashboard/short-news',
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */'@/views/login/index.vue'),
		meta: {
			layout: 'LoginLayout'
		}
	},
    {
		path: '/',
		redirect: {
			name: 'dashboard-short-news'
		},
		component: { render: () => h(RouterView) },
		meta: {
			layout: 'MainLayout'
		},
		children: [
			{
				path: 'shipping-matrix',
				name: 'shipping-matrix',
				component: () => import(/* webpackChunkName: "shipping-matrix" */'@/views/shipping-matrix/index.vue'),
			},
			{
				path: 'road-surface',
				name: 'road-surface',
				component: () => import(/* webpackChunkName: "road-surface" */'@/views/road-surface/index.vue'),
			},
			{
				path: 'surface-mapping',
				name: 'surface-mapping',
				component: () => import(/* webpackChunkName: "surface-mapping" */'@/views/surface-mapping/index.vue'),
			},
			{
				path: 'tubes',
				name: 'tubes',
				component: () => import(/* webpackChunkName: "tubes" */'@/views/tubes/index.vue'),
			},
			{
				path: 'information-download',
				name: 'information-download',
				component: () => import(/* webpackChunkName: "information-download" */'@/views/information-download/index.vue'),
			},
			{
				path: 'file-editor',
				name: 'file-editor',
				component: () => import(/* webpackChunkName: "file-editor" */'@/views/file-editor/index.vue'),
			},
			{
				path: 'recommended-items',
				name: 'recommended-items',
				component: () => import(/* webpackChunkName: "recommended-items" */'@/views/recommended-items/index.vue'),
			},
			{
				path: 'dashboard/short-news',
				name: 'dashboard-short-news',
				component: () => import(/* webpackChunkName: "dashboard-short-news" */'@/views/dashboard/short-news/index.vue'),
			},
			{
				path: 'dashboard/journal',
				name: 'dashboard-journal',
				component: () => import(/* webpackChunkName: "dashboard-journal" */'@/views/dashboard/journal/index.vue'),
			},
			{
				path: 'dashboard/journal-offers',
				name: 'dashboard-journal-offers',
				component: () => import(/* webpackChunkName: "dashboard-journal-offers" */'@/views/dashboard/journal-offers/index.vue'),
			},
			{
				path: 'dashboard/journal-sort-order',
				name: 'dashboard-journal-sort-order',
				component: () => import(/* webpackChunkName: "dashboard-journal-sort-order" */'@/views/dashboard/journal-sort-order/index.vue'),
			},
			{
				path: 'language-management',
				name: 'language-management',
				component: () => import(/* webpackChunkName: "language-management" */'@/views/language-management/index.vue'),
			},
			{
				path: 'tubes-mapping',
				name: 'tubes-mapping',
				component: () => import(/* webpackChunkName: "tubes-mapping" */'@/views/tubes-mapping/index.vue'),
			},
			{
				path: 'payment-delivery',
				name: 'payment-delivery',
				component: () => import(/* webpackChunkName: "payment-delivery" */'@/views/payment-delivery/index.vue'),
			},
			{
				path: 'tutorials',
				name: 'tutorials',
				component: () => import(/* webpackChunkName: "tutorials" */'@/views/tutorials/index.vue'),
			},
			{
				path: 'tutorials/sort-order',
				name: 'tutorials-sort-order',
				component: () => import(/* webpackChunkName: "tutorials-sort-order" */'@/views/tutorials/sort-order/index.vue'),
			},
			{
				path: 'bikes-footnotes',
				name: 'bikes-footnotes',
				component: () => import(/* webpackChunkName: "bikes-footnotes" */'@/views/bikes-footnotes/index.vue'),
			},
			{
				path: 'motorcycle-brand-block',
				name: 'motorcycle-brand-block',
				component: () => import(/* webpackChunkName: "motorcycle-brand-block" */'@/views/motorcycle-brand-block/index.vue'),
			},
			{
				path: 'premium-tyres',
				name: 'premium-tyres',
				component: () => import(/* webpackChunkName: "premium-tyres" */'@/views/premium-tyres/index.vue'),
			},
			{
				path: 'login-count',
				name: 'login-count',
				component: () => import(/* webpackChunkName: "login-count" */'@/views/login-count/index.vue'),
			},
			{
				path: 'pairs-management',
				name: 'pairs-management',
				component: () => import(/* webpackChunkName: "pairs-management" */'@/views/pairs-management/index.vue'),
			}
		]
	}
]

const locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');

const router = createRouter({
  history: createWebHistory((locale.trim().length && locale != '/' && SUPPORTED_LANGUAGES.includes(locale)) ? `/${locale}` : 'de'),
  routes,
});

router.beforeEach(async (to, from, next) => {
	const locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');
	const savedLang = sessionStorage.getItem('lang');
	if (locale === '/') {
		next(false);
		window.location.href = '/' + SUPPORTED_LANGUAGES[0];
		return;
	}
	if (
		to.name !== 'login' &&
		savedLang && locale !== savedLang
	) {
		next(false);
		window.location.href = '/' + savedLang + to.fullPath;
		return;
	}
	if (document.documentElement.lang && locale !== document.documentElement.lang) {
		next(false);
		window.location.href = '/' + document.documentElement.lang + to.fullPath;
		return;
	}

	await routeMiddleware(locale);

	const bearer = getCookie('user_grant_access');

	if (bearer) {
		try {
			await UserService.checkToken();
			if (to.name === 'login') {
				next({ name: 'dashboard-short-news' });
			} else {
				next();
			}
		} catch (err: any) {
			removeCookie('user_grant_access');
			window.location.href = '/login';
		}
	}

	if (to.name === 'login') {
		next();
	} else {
		next({ name: 'login' });
	}
});

export default router;